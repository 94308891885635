<template>
  <v-app class="pr-4">
    <HeaderApp v-if="!$route.meta.altHeader && !hideNavigation"/>
    <audio style="display: none" id="remoteAudio" ref="remoteAudio" controls>
      <p>Your browser doesn't support HTML5 audio.</p>
    </audio>

    <audio style="display: none" id="localAudio" ref="localAudio" controls>
      <p>Your browser doesn't support HTML5 audio.</p>
    </audio>
    <div
      :class="{ 'z-10': isMobile }" style="box-shadow:0px 1px 0 0 #b0bec569"
      class="d-flex px-5 py-3 white align-center justify-space-between header-part"
    >
      <v-img
        position="left center"
        :src="require('@/assets/logo-new.svg')"
        height="22"
        width="84px"
        contain
      ></v-img>
      <div v-if="hideNavigation" class="logout">
        <v-btn @click="logoutUser">Logout</v-btn>
      </div>
      <v-app-bar-nav-icon
        v-else-if="isMobile"
        class="hamburger"
        @click.stop="sidebarMenu = !sidebarMenu"
      />
    </div>

    <v-navigation-drawer
      v-if="!hideNavigation"
      v-model="sidebarMenu"
      app
      floating
      :permanent="$vuetify.breakpoint.mdAndUp"
      :mini-variant="isMenuCollapsed && !isMobile"
      color="#F4F6F8"
      class="sidebar"
      :width="isMenuCollapsed && !isMobile ? '75px' : '300px'"
      height="100%"
    >
      <v-list dense :color="$route.meta.altHeader ? '#F9FAFB' : '#00084B'" dark
              :class="{'nav-list-alt': $route.meta.altHeader}">
        <v-list-item :class="isMenuCollapsed && !isMobile ? 'pa-0 d-flex align-center justify-center' : 'ml-1'" class="relative">
          <v-img
            v-if="!isMenuCollapsed"
            position="left center"
            :src="require($route.meta.altHeader ? '@/assets/logo-new.svg' : '@/assets/logo.svg')"
            height="22"
            contain
          ></v-img>
          <v-btn
            text
            class="text-none align-center"
            height="36px"
            width="36px"
            min-width="auto"
            @click="setMenuCollapsed(!isMenuCollapsed)"
          >
            <v-icon color="#F9FAFB">menu</v-icon>
          </v-btn>
        </v-list-item>
      </v-list>
      <v-list :class="{ white: isMobile }" width="calc(100% - 1px)">
        <v-list-item v-if="isMobile">
          <v-list-item-content>
            <v-list-item-title class="font-weight-bold fz-22">Menu</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-for="item in itemsFiltered"
          :key="item.title"
          link
          :to="item.href"
          dense
          active-class="nav-menu-item--active"
          :class="{ 'mt-2': item.gap, 'nav-menu-item--active': isChildRoute(item) }"
          class="nav-menu-item"
        >
          <v-list-item-icon class="mr-4 relative">
            <v-icon size="20px">{{ item.icon }}</v-icon>
            <v-icon v-if="item.smallIcon" size="11px" class="smallIcon">{{ item.smallIcon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title
              :class="isMobile ? 'fz-16 font-weight-regular' : 'fz-14 font-weight-medium'"
              :style="{ color: isMobile ? '#191E24' : '#637381' }"
            >{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!--        <v-list-item-->
        <!--          v-if="($route.path === '/settings' || $route.path === '/payments') && this.$store.state.auth.currentUser && this.$store.state.auth.currentUser.role !== 'account_admin'"-->
        <!--          link-->
        <!--          to="/settings"-->
        <!--          dense-->
        <!--          class="nav-menu-item&#45;&#45;active nav-menu-item mt-2"-->
        <!--        >-->
        <!--          <v-list-item-icon class="mr-4">-->
        <!--            <v-icon size="20px">settings</v-icon>-->
        <!--          </v-list-item-icon>-->
        <!--          <v-list-item-content>-->
        <!--            <v-list-item-title>Settings</v-list-item-title>-->
        <!--          </v-list-item-content>-->
        <!--        </v-list-item>-->
      </v-list>
      <v-list class="d-md-none">
        <user-menu/>
      </v-list>
    </v-navigation-drawer>
    <v-main
      :style="{ 'padding':
        hideNavigation ? '56px 0 0 0' :
        !isMenuCollapsed && !isMobile ? '56px 0 0 300px' :
        isMobile ? '0px' : '56px 0 0 56px'
      }"
      class="py-0 my-0 mt-lg-2">
      <v-container v-if="!loadingDidCount" class="px-md-6 px-0 py-0 fill-height d-block" fluid>
        <v-row class="fill-height no-gutters h-full" style="margin-inline:3px">
          <v-col
            cols="12"
            md="8"
            class="py-0 px-0"
          >
            <transition name="fade">
              <router-view :key="$route.path"></router-view>
            </transition>
          </v-col>
            <!-- v-if="mainPagesUrls.includes($route.path)" es componentenr@ edit profileum el karan bacven. kod@ nenc gri vor normal ashxati -->
          <v-col
            cols="12"
            md="4"
            class="pl-md-4"
            :class="['pt-md-0', {'dashboard-page-class': $route.path === '/'}]"
          >
            <AssignedMailboxes v-if="$route.path === '/user-profile'" class="mt-12"/>
            <CallbackRequested
              v-if="$route.name === 'MailboxHide' && $route.path !== '/mailbox/callback' && !contactDetails && !this.callbackRequestedMailbox && !this.playMessage && $store.state.auth.currentUser.role === 'account_admin'"
              class="mb-5"/>
            <CallbackRequestedMailbox v-if="$route.name === 'Mailbox' && this.callbackRequestedMailbox" class="mb-5"/>
            <PlayMessage v-if="$route.name === 'Mailbox' && this.playMessage" class="mb-5"/>
            <Analytics
              v-if="$route.name === 'MailboxHide' && $route.path !== '/mailbox/callback' && !contactDetails && $store.state.auth.currentUser.role === 'account_admin'"
              class="mb-5"
            />
            <VoicemailMyMailboxes
              v-if="($route.name === 'VoicemailsMailboxes' || (rightPopUpMyMailboxes && $route.name === 'EditProfile')) && $store.state.auth.currentUser.role === 'account_admin'"
              class="mb-5"
            />
            <IncomingCalls v-if="incomingCalls" class="mb-5 active-calls"/>
            <!-- <NewMessageNotification v-if="$route.path === '/'" class="mb-5"/> -->
            <HistoryIncomingCallVideo v-if="$route.path === '/chat' && this.chatView.videoComponent" class="mb-5"/>
            <HistoryIncomingCallUserInformation
              v-if="$route.path === '/chat' && this.chatView.informationComponent"
              class="mb-5"
            />
            <HistoryIncomingCallRecord v-if="$route.path === '/chat' && this.chatView.recordComponent" class="mb-5"/>
            <HistoryIncomingCallSendTo v-if="$route.path === '/chat' && this.chatView.sendComponent" class="mb-5"/>
            <HistoryIncomingCallPlayMessage
              v-if="$route.path === '/chat' && this.chatView.messageComponent"
              class="mb-5"
            />
            <HistoryIncomingCallInvite
              v-if="$route.path === '/chat' && this.chatView.inviteComponent"
              class="mb-5"
            />
            <RightPopUpMyContacts
              v-if="showPopUpContactDetails && rightPopUpMyContacts"
              :assignMember="$route.path === '/numbers' || $route.path === '/members' || $route.params.type === 'memberDetails'"
              :callParticipants="$route.path === '/active-calls' || $route.name === 'Mailbox'"
              class="mb-5"
            />
            <PopUpContactsDetails class="mb-5"
              v-if="$route.path === '/contacts' && contactDetails ||
                $route.name === 'Mailbox' && contactDetails && $route.path !== '/mailbox/callback' && $store.state.auth.currentUser.role !== 'account_admin'"/>
           </v-col>
        </v-row>
        <v-btn
          v-if="checkDevice && showBtn && !mobileSearch && !dialBtnBlockedPages.includes($route.path)"
          fab
          fixed
          top
          right
          dark
          depressed
          width="60"
          height="60"
          class="dial-btn mt-15 mr-1"
          :class="{ 'mobile-dial-btn': isMobile, 'disabled': !didCount  }"
          @click="keypadButton(keypadStatus = !keypadStatus)"
          :style="keypadStatus ? 'z-index: 102' : 'z-index: 1'"
        >
          <v-icon>
            {{ keypadStatus ? 'close' : 'dialpad' }}
          </v-icon>
        </v-btn>
        <keypad v-if="keypadStatus" :value="keypadStatus"/>
        <CallModal v-if="$store.state.components.callStatus.status" :value="$store.state.components.callStatus.status"/>
        <IncomingCallModal v-if="$store.state.components.callIncomingStatus.status" :value="keypadStatus"/>
        <subscribe-dialog v-if="subscribeDialog" />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import Keypad from '../components/Keypad'
import IncomingCallModal from '../components/IncomingCallModal'
import IncomingCalls from '@/components/IncomingCalls'
import CallModal from '@/components/CallModal'
import CallbackRequested from '@/components/CallbackRequested'
import CallbackRequestedMailbox from '@/components/CallbackRequestedMailbox'
import PlayMessage from '@/components/PlayMessage'
import HeaderApp from '@/components/HeaderApp'
/* import NewMessageNotification from '@/components/NewMessageNotification' */
import HistoryIncomingCallVideo from '@/components/HistoryIncomingCallVideo'
import Analytics from '@/components/Analytics'
import VoicemailMyMailboxes from '@/components/VoicemailMyMailboxes'
import HistoryIncomingCallRecord from '@/components/HistoryIncomingCallRecord'
import HistoryIncomingCallSendTo from '@/components/HistoryIncomingCallSendTo'
import HistoryIncomingCallUserInformation from '@/components/HistoryIncomingCallUserInformation'
import HistoryIncomingCallPlayMessage from '@/components/HistoryIncomingCallPlayMessage'
import HistoryIncomingCallInvite from '@/components/HistoryIncomingCallInvite'
import RightPopUpMyContacts from '@/components/RightPopUpMyContacts'
import PopUpContactsDetails from '@/components/PopUpContactsDetails'
import AssignedMailboxes from '@/components/AssignedMailboxes'
import UserMenu from '../components/UserMenu'
import SubscribeDialog from '@/components/SubscribeDialog'
// import CryptoJS from 'crypto-js'
import jwtDecode from 'jwt-decode'
import { Role } from '@/_helpers/role'

import {
  connectSIP,
  unregisterSIP, disconnectSIP,
  call,
  getSessionType,
  addSession,
  getNumberOfSession,
  removeSessionFromCollector, isOutboundCall, updateExtraHeaders,
  sm, SIP_SERVER, SIP_SERVER_PORT, beep
} from '../utilities/sessionManager'
import { playAudioRing, stopAudioRing } from '../utilities/audio-sounds'
import { initSocket, socket, closeSocket } from '../utilities/socketIO'
// import locationByArea from '@/imports/locationByArea'

export default {
  name: 'MainLayout',
  components: {
    UserMenu,
    PopUpContactsDetails,
    RightPopUpMyContacts,
    HistoryIncomingCallInvite,
    HistoryIncomingCallUserInformation,
    HistoryIncomingCallSendTo,
    HistoryIncomingCallRecord,
    HistoryIncomingCallPlayMessage,
    HistoryIncomingCallVideo,
    Analytics,
    /* NewMessageNotification, */
    HeaderApp,
    CallbackRequested,
    CallbackRequestedMailbox,
    IncomingCalls,
    Keypad,
    // eslint-disable-next-line vue/no-unused-components
    IncomingCallModal,
    CallModal,
    VoicemailMyMailboxes,
    AssignedMailboxes,
    PlayMessage,
    SubscribeDialog
  },
  data: () => ({
    validPhoneLength: 11,
    openCall: false,
    isCallOnHold: true,
    disableHoldButton: true,
    right_box: 'contact_history',
    sipToken: null,
    sipTokenEndTime: 0,
    sipTokenTimeoutId: null,
    flagToUpdateSipToken: false,
    // domain: 'fonio2.sip.stage.opentact.org',
    // port: '5443',
    username: '',
    password: '',
    audioConstraints: {
      audio: true,
      video: false
    },
    simpleUser: null,
    incomingCall: null,
    sockets: {
      connect: function () {
        console.log('socket connected')
      },
      sms_incoming: function (data) {
        console.log(
          'this method was fired by the socket server. eg: io.emit("customEmit", data)'
        )
        console.log(data)
      }
    },
    plan: {},
    // startTimer: false,
    callIsActive: false,
    sipUserRegistered: false,

    phoneOnCall: '',
    active: false,
    // keypadStatus: false,
    // mute: false,
    callReceived: false,
    callPick: false,
    callEnd: false,
    notOpenCall: true,
    callOption: true,
    callKeypad: false,
    callModalStatus: false,
    phone: '',
    sipAccess: {},
    inContact: false,
    voiceCall: false,
    isRegistered: false,
    isIncomingCall: false,
    user: {},
    checkDevice: true,
    // ---------------//
    mini: false,
    sidebarMenu: true,
    items: [
      {
        title: 'Dashboard',
        href: '/',
        icon: 'home',
        roles: [Role.User, Role.CompanyAdmin, Role.SystemAdmin, Role.SuperAdmin]
      },
      {
        title: 'Phone Numbers',
        href: '/phone-numbers',
        icon: 'sim_card',
        roles: [Role.CompanyAdmin, Role.SystemAdmin, Role.SuperAdmin],
        children: ['BuyNewNumber']
      },
      {
        title: 'Members',
        href: '/members',
        icon: 'group',
        roles: [Role.CompanyAdmin, Role.SystemAdmin, Role.SuperAdmin],
        children: ['NewMember', 'InviteMembers', 'memberDetails']
      },
      {
        title: 'Callflow',
        href: '/callflow',
        icon: 'dialpad',
        roles: [Role.CompanyAdmin, Role.SystemAdmin, Role.SuperAdmin],
        children: ['CreateCallflow', 'EditCallflow']
      },
      {
        title: 'Mailboxes',
        href: '/mailboxes',
        icon: 'move_to_inbox',
        roles: [Role.User, Role.CompanyAdmin, Role.SystemAdmin, Role.SuperAdmin],
        children: ['CreateMailbox', 'EditMailbox', 'Mailbox']
      },
      {
        title: 'Activity Log',
        href: '/activity-log',
        icon: 'phone',
        smallIcon: 'schedule',
        roles: [Role.CompanyAdmin, Role.SystemAdmin, Role.SuperAdmin]
      },
      {
        title: 'Active Calls',
        href: '/active-calls',
        icon: 'phone',
        roles: [Role.CompanyAdmin, Role.SystemAdmin, Role.SuperAdmin]
      },
      // {
      //   title: 'Invoice',
      //   href: '/invoice',
      //   icon: 'payment',
      //   roles: [Role.CompanyAdmin, Role.SystemAdmin, Role.SuperAdmin]
      // },
      {
        title: 'Contacts',
        href: '/contacts',
        icon: 'person',
        roles: [Role.User, Role.CompanyAdmin, Role.SystemAdmin, Role.SuperAdmin],
        children: ['NewContact', 'contactDetails']
      },
      {
        title: 'Voicemails',
        href: '/voicemails',
        icon: 'voicemail',
        roles: [Role.CompanyAdmin, Role.SystemAdmin, Role.SuperAdmin]
      },
      {
        title: 'Recordings',
        href: '/recordings',
        icon: 'record_voice_over',
        roles: [Role.CompanyAdmin, Role.SystemAdmin, Role.SuperAdmin]
      }
      // {
      //   title: 'Queues',
      //   href: '/queues',
      //   icon: 'people',
      //   roles: [Role.User, Role.CompanyAdmin, Role.SystemAdmin, Role.SuperAdmin]
      // },
      // {
      //   title: 'History',
      //   href: '/history',
      //   icon: 'history',
      //   roles: [Role.User, Role.CompanyAdmin, Role.SystemAdmin, Role.SuperAdmin]
      // },
      // {
      //   title: 'Settings',
      //   href: '/settings',
      //   icon: 'settings',
      //   roles: [Role.CompanyAdmin, Role.SystemAdmin, Role.SuperAdmin],
      //   children: ['AddPaymentMethod', 'EditPaymentMethod']
      // },
      // {
      //   title: 'Payments History',
      //   href: '/payments-history',
      //   icon: 'watch_later',
      //   roles: [Role.CompanyAdmin, Role.SystemAdmin, Role.SuperAdmin]
      // }
    ],
    chat: {
      message: '',
      attachFile: null,
      isCalling: false,
      messageList: [
        {
          user: false,
          sms: false,
          note: 'Do you want to download free song for ipod? If so, reading this article could save you from getting in to a lot of trouble! Downloading music to your Ipod has more than one pitfall associated with it, and this article will tell you the best way to download free song for Ipod.',
          time: '11:29 pm',
          date: '2016-08-02T11:23:50.245Z'
        },
        {
          user: true,
          time: '11:29 pm',
          date: '2016-08-02T11:23:50.245Z',
          call: {
            status: 'incoming'
          }
        },
        {
          user: true,
          time: '11:29 pm',
          date: '2016-08-02T11:23:50.245Z',
          component: {
            status: 'record',
            alert: false
          }
        }
      ]
    },
    refreshIntervalId: '',
    dialBtnBlockedPages: [
      '/settings',
      '/add-payment-method'
    ],
    loadingDidCount: false
  }),
  methods: {
    ...mapActions(['getLiveCalls', 'getSipToken', 'logout', 'didNumbersList']),
    ...mapMutations('components', ['setMenuCollapsed']),
    async loadLiveCalls () {
      try {
        const { data } = await this.getLiveCalls({
          take: 999
        })
        return data.payload.items
      } catch (e) {
        throw new Error(e)
      }
    },
    async updateSipToken () {
      const { data } = await this.getSipToken()
      if (data.token) {
        this.sipToken = data.token
      }
    },
    getActiveCall (activeCalls, session) {
      const sessionNumber = getNumberOfSession(session)
      const isOutboundSession = isOutboundCall(session)
      const destinationField = isOutboundSession ? 'dnis' : 'ani'
      return activeCalls.find(call => call[destinationField] === sessionNumber)
    },
    checkIsDeviceEnableOnPC () {
      navigator.mediaDevices.getUserMedia({ video: false, audio: true }).then((stream) => {
        this.init()
        this.checkDevice = true
      }).catch((err) => {
        this.checkDevice = false
        console.error(`you got an error: ${err}`)
      })
    },
    keypadButton (payload) {
      if (!this.didCount) return
      this.$store.dispatch('components/keypadStatus', payload)
    },

    init () {
      // if (!this.plan.monthlyAmount || this.plan.monthlyAmount == 1.9) {
      //   return
      // }
      // eslint-disable-next-line camelcase
      const remote_audio = this.$refs.remoteAudio
      if (this.sipAccess.sip_username) {
        this.connect()
      }
    },

    async hangup () {
      await this.$store.dispatch('components/hold', true)
      await this.$store.dispatch('components/mute', true)
      // await this.$store.dispatch('components/startTimerEvent', false)
      // await this.$store.dispatch('components/callModalStatus', { phone: '', status: false })
      this.voiceCall = this.chat.isCalling = false
      // this.chat.messageList[index].call.status = 'finished'

      this.callReceived = false
      if (!(sm && sm.session)) {
        return
      }

      this.simpleUser.hangup().catch((error) => {
        console.error(`[${this.simpleUser.id}] failed to hangup call`)
        console.error(error)
      })
      beep(false)
    },

    async toCall () {
      this.user = this.userInfo

      console.log(this.user)
      this.chat.messageList.push({
        user: false,
        time: (new Date()).toLocaleString('en-US', {
          hour: 'numeric',
          minute: 'numeric',
          hour12: true
        }),
        date: (new Date().toISOString()),
        call: {
          status: 'calling'
        }
      })

      this.voiceCall = this.chat.isCalling = true
      // this.openCall = true
      this.disableHoldButton = true
      this.isCallOnHold = true
      this.notOpenCall = false

      if (!sm) {
        console.log('Trying connect')
        await this.connect()
      }
      if (!sm) {
        console.log('not connected')
        return
      }
      // const target = `sip:${this.$store.state.components.callStatus.phone}@${this.domain}`
      // console.log('target', target)
      call(this.$store.state.components.callStatus.phone)
      beep(true)
    },

    async connect () {
      const displayName = 'Fonio'

      const self = this
      const simpleUserDelegate = {
        onCallDTMFReceived: (session, tone, duration) => {
          console.log('onCallDTMFReceived: ', session, tone, duration)
        },
        onCallCreated: (session) => {
          const sessionType = getSessionType(session)
          const _isOutboundCall = isOutboundCall(session)
          console.log('Session type: ', sessionType)
          const sessionId = session.id
          addSession(session, sessionType, session.state)
          const number = getNumberOfSession(session)
          console.log('Number: ', number)
          console.log('Session: ', session)
          const payload = {
            type: sessionType,
            state: session.state,
            status: _isOutboundCall ? 'calling' : 'incoming',
            id: sessionId,
            uuidActiveCall: null,
            mute: false,
            hold: false,
            disabledButtons: _isOutboundCall,
            time: 0,
            timerId: null,
            firstname: '',
            lastname: '',
            location: '',
            avatar: 'https://picsum.photos/200',
            phoneNumber: number,
            active: _isOutboundCall,
            checked: false,
            record: false
          }
          this.$store.commit('components/addCall', payload)
          this.$store.dispatch('getCallContact', number)
            .then((res) => {
              const _call = {
                id: sessionId,
                checked: true
              }
              const contact = res.data?.contactList[0]
              if (contact) {
                _call.firstname = contact.firstName
                _call.lastname = contact.lastName
              }
              // else {
              //   const area = call.phone.substring(2, 5)
              //   const location = locationByArea.find(e => e.area === area)
              //   _call.location = location ? location.location : 'Unknown'
              // }
              this.$store.commit('components/updateCall', _call)
            })
            .catch(() => {
              console.log(`[${displayName}] Can't find: ${number}`)
            })
          if (isOutboundCall(session)) {
            this.$store.commit('components/disableMuteOutboundButton', true)
            this.$store.commit('components/disableHoldOutboundButton', true)
          }
          console.log(`[${displayName}] Call created`)
        },
        onCallAnswered: (session) => {
          stopAudioRing()
          this.$store.commit('components/updateCall', {
            id: session.id,
            status: 'answered',
            active: true,
            disabledButtons: false,
            timerId: setInterval(() => {
              const t = this.$store.state.components.calls.find(c => c.id === session.id).time
              this.$store.commit('components/updateCall', {
                id: session.id,
                time: t + 1
              })
            }, 1000)
          })
          if (isOutboundCall(session)) {
            this.$store.commit('components/disableMuteOutboundButton', false)
            this.$store.commit('components/disableHoldOutboundButton', false)
            this.loadLiveCalls()
              .then(calls => {
                console.log(calls)
                if (calls.length !== 0) {
                  const activeCall = this.getActiveCall(calls, session)
                  this.$store.commit('components/updateCall', {
                    id: session.id,
                    uuidActiveCall: activeCall?.uuid ?? null,
                    is_spam: activeCall?.is_spam
                  })
                }
              })
              .catch((error) => {
                console.error(error)
              })
            // this.$store.dispatch('components/startTimerEvent', true)
          }
          this.$store.commit('components/updateCall', {
            id: session.id,
            active: true,
            time: true
          })
          beep(false)
          console.log(`[${displayName}] Call answered`)
        },
        onCallHangup: (session) => {
          stopAudioRing()
          beep(false)
          // this.$store.dispatch('components/callIncomingBoxStatus', false)
          if (isOutboundCall((session))) {
            this.$store.commit('components/disableMuteOutboundButton', true)
            this.$store.commit('components/disableHoldOutboundButton', true)
          }
          this.$store.dispatch('components/callModalStatus', { phone: '', status: false })

          this.notOpenCall = true
          this.callReceived = false
          this.callPick = false
          this.voiceCall = this.chat.isCalling = false

          // this.$store.dispatch('components/startTimerEvent', false)
          const timerId = this.$store.state.components.calls.find(c => c.id === session.id)?.timerId
          if (timerId) {
            clearInterval(timerId)
          }
          removeSessionFromCollector(session)
          this.$store.commit('components/removeCall', session.id)
          console.log(`[${displayName}] Call hangup`)
        },
        onCallHold: (session, held) => {
          stopAudioRing()
          if (!isOutboundCall(session)) {
            this.$store.commit('components/holdCall', {
              id: session.id,
              hold: held
            })
          }
          console.log(`[${displayName}] Call hold ${held}`)
          // holdCheckbox.checked = held;
        },
        onCallReceived: async (session) => {
          playAudioRing()
          // await this.$store.dispatch('components/callIncomingModalStatus', { phone: '', status: true })
          await this.$store.dispatch('components/callIncomingBoxStatus', true)
          // await this.$store.dispatch('components/callAnswered', null)

          console.log(`[${displayName}] Call received`)
          // await this.$store.dispatch('components/keypadStatus', true)
          this.isReceivedCall = true
          this.callKeypad = true
          this.callReceived = true
          // this.mute = true
          this.isCallOnHold = true
          this.notOpenCall = false
          this.loadLiveCalls().then(calls => {
            if (calls.length !== 0) {
              const activeCall = this.getActiveCall(calls, session)
              this.$store.commit('components/updateCall', {
                id: session.id,
                uuidActiveCall: activeCall?.uuid ?? null,
                is_spam: activeCall?.is_spam
              })
            }
          })
        },
        onRegistered: () => {
          self.sipUserRegistered = true
          console.log(`[${displayName}] Registered`)
          // self.setStatus("connected");
        },
        onServerConnect: () => {
          console.log(`[${displayName}] Server Connected`)
          this.$store.commit('components/setSipConnected', true)
        },
        onServerDisconnect: (error) => {
          stopAudioRing()
          this.$store.commit('components/setSipConnected', false)
          this.$store.dispatch('components/callModalStatus', { phone: '', status: false })
          console.log(`[${displayName}] Server Disconnected`, error)
        }
      }
      const remoteAudio = this.$refs.remoteAudio
      // console.log('remoteAudio', remoteAudio)
      const simpleUserOptions = {
        delegate: simpleUserDelegate,
        media: {
          remote: {
            audio: remoteAudio
          }
        },
        userAgentOptions: {
          displayName,
          // logBuiltinEnabled: false
          logLevel: 'debug'
        }
      }
      console.log('simpleUserOptions', simpleUserOptions)

      const wssServer = `wss://${SIP_SERVER}:${SIP_SERVER_PORT}`
      const aor = `sip:${this.sipAccess.sip_username}@${SIP_SERVER}`
      console.log('aor', aor)

      // const ha1 = CryptoJS.MD5(`${this.sipAccess.sip_username}:${SIP_SERVER}:${this.sipAccess.password}`).toString()
      // console.log('HA1B: ', `${this.sipAccess.sip_username}:${SIP_SERVER}:${this.sipAccess.password}`, ha1, aor)

      if (!this.sipToken) {
        await this.updateSipToken()
      }
      const registererOptions = {
        extraHeaders: [
          `Authorization: JWT ${this.sipToken}`
        ]
      }
      await connectSIP(wssServer, {
        ...simpleUserOptions,
        registererOptions,
        userAgentOptions: {
          ...simpleUserOptions.userAgentOptions
          // authorizationHa1: ha1,
          // authorizationPassword: this.sipAccess.password,
          // authorizationUsername: this.sipAccess.sip_username
          // authorizationUsername: this.username
        },
        aor
      })
      this.subscribeTokenExpiredTime(true)
    },
    subscribeTokenExpiredTime (subscribe) {
      if (subscribe && !this.flagToUpdateSipToken) {
        this.flagToUpdateSipToken = subscribe
        this.updateSipTokenWhenExpired()
      }
      // this.flagToUpdateSipToken = subscribe
      // this.setUpdateSipTokenTimeout(null)
    },
    _jwtDecode (token) {
      try {
        return jwtDecode(token)
      } catch (error) {
        return undefined
      }
    },
    async updateSipTokenAndWssConnection () {
      this.flagToUpdateSipToken = false
      await this.updateSipToken()
      updateExtraHeaders(this.sipToken)
      this.updateSipTokenWhenExpired()
    },
    updateSipTokenWhenExpired () {
      const DTO = this._jwtDecode(this.sipToken)
      if (DTO) {
        let time = DTO.exp * 1000 - 60 * 1000 - Date.now()
        time = time < 0 ? 0 : time
        this.sipTokenEndTime = time
        this.setUpdateSipTokenTimeout(setTimeout(async () => {
          await this.updateSipTokenAndWssConnection()
        }, time))
      }
    },

    setUpdateSipTokenTimeout (timeoutId) {
      if (this.sipTokenEndTime !== 0) {
        clearInterval(this.sipTokenTimeoutId)
      }
      this.sipTokenTimeoutId = timeoutId
    },
    isChildRoute (item) {
      return item.children &&
        (item.children.includes(this.$route.name) ||
          (this.$route.params.type && item.children.includes(this.$route.params.type))
        )
    },
    async countDIDs () {
      try {
        this.loadingDidCount = true
        await this.didNumbersList({})
      } catch (err) {
        throw new Error(err)
      } finally {
        this.loadingDidCount = false
      }
    },
    async logoutUser () {
      try {
        await this.$router.push({ path: '/login' })
        this.logout()
      } catch (e) {
        throw new Error(e)
      }
    }
  },
  computed: {
    ...mapGetters([
      'currentCompany'
    ]),
    isMobile () {
      return this.$vuetify.breakpoint.smAndDown
    },
    mainPagesUrls () {
      return this.items.map(item => item.href)
    },
    showBtn () {
      return this.$store.state.components.sipConnected && !this.currentCompany?.is_free
    },
    incomingCalls () {
      return this.$store.state.components.calls.length !== 0
    },
    ...mapState('components', [
      'chatView',
      'keypad',
      'callStatus',
      'rightPopUpMyContacts',
      'rightPopUpMyMailboxes',
      'callbackRequested',
      'callbackRequestedMailbox',
      'playMessage',
      'contactDetails',
      'userInfo'
    ]),
    ...mapGetters('components', ['mobileSearch', 'isMenuCollapsed', 'subscribeDialog']),
    ...mapGetters(['didCount']),
    media () {
      const audio = document.getElementById('remoteAudio')
      const audio2 = document.getElementById('localAudio')
      return {
        stream: this.audioConstraints,
        render: {
          remote: audio,
          local: audio2
        }
      }
    },
    keypadStatus: {
      get () {
        // console.log('get 1111', this.keypad.status)
        return this.keypad.status
      },
      set (newValue) {
        // console.log('set 2222', this.keypad.status)
        return newValue
      }
    },
    itemsFiltered () {
      return this.$store.state.auth.currentUser ? this.items.filter(item => item.roles.includes(this.$store.state.auth.currentUser.role)) : []
    },
    showPopUpContactDetails () {
      const memberDetails = this.$route.name === 'UserProfile' && this.$route.params.type === 'memberDetails'
      return (this.$route.path === '/numbers' || this.$route.path === '/active-calls' || this.$route.name === 'CreateCallflow' || this.$route.name === 'EditCallflow' ||
             this.$route.path === '/phone-numbers' || this.$route.path === '/mailboxes' || this.$route.name === 'Mailbox' || this.$route.path === '/members' ||
             memberDetails)
    },
    isFreeAccount () {
      return this.currentCompany?.is_free
    },
    hideNavigation () {
      return this.isFreeAccount && !this.didCount
    }
  },
  async created () {
    this.sipAccess = JSON.parse(localStorage.getItem('sipAccess'))
    this.user = {
      ...this.userInfo
    }

    if (this.isNew) {
      this.user.firstName = this.user.lastName = this.user.phoneNumber = ''
      this.chat.messageList = []
    }
    initSocket(this.$store.state.auth.currentUser.token)

    socket.on('tnEvents', (tnEvents) => {
      // console.log('tnEvents: ', tnEvents)
      this.$store.commit('components/setNewSms', { data: tnEvents, type: 'any' })
    })
    await this.countDIDs()
    if (this.isFreeAccount) {
      if (!this.didCount && !['PhoneNumbers', 'BuyNewNumber'].includes(this.$route.name)) return this.$router.push({ name: 'BuyNewNumber' })
    }
  },
  async mounted () {
    await this.updateSipToken()
    this.subscribeTokenExpiredTime(true)
    this.checkIsDeviceEnableOnPC()

    if (this.isVideo) {
      this.$store.dispatch('components/chatVideoCall', true)
    }
    this.$root.$toCall = this.toCall
  },
  async beforeDestroy () {
    closeSocket()
    await clearInterval(this.sipTokenTimeoutId)
    await unregisterSIP(this.sipToken)
    await disconnectSIP()
  },
  watch: {
    '$store.state.components.callStatus': function (callData) {
      if (callData.status === true) {
        console.log('store.state.components.callStatus')
        this.$store.dispatch('components/keypadStatus', false)
        this.toCall()
      }
    },
    $route (to, from) {
      this.$store.commit('components/setChatView',
        {
          inviteComponent: false,
          videoComponent: false,
          sendComponent: false,
          messageComponent: false,
          recordComponent: false,
          informationComponent: false
        }
      )
      if (this.rightPopUpMyContacts) this.$store.dispatch('components/RightPopUpMyContacts', false)
      if (this.rightPopUpMyMailboxes) this.$store.dispatch('components/RightPopUpMyMailboxes', false)
      if (this.hideNavigation && !['PhoneNumbers', 'BuyNewNumber'].includes(to.name)) return this.$router.push({ name: 'BuyNewNumber' })
    },
    userInfo (val) {
      this.user = {
        ...val
      }
    },
    '$store.state.keypad.status': function (newVal) {
      console.log(newVal)
    },
    isCalling (val) {
    }
  }

}
</script>

<style scoped lang="scss">
  .hamburger {
    position: sticky;
    top: 10px;
    left: 9px;
    z-index: 101;
  }

  .border-bottom {
    border-bottom: 1px solid rgba(176, 190, 197, 0.41) !important;
  }

  .mt-230 {
    @include media-breakpoint-up(md) {
      margin-top: 230px !important;
    }
  }

  .header-part {
    border-bottom: 1px solid  #EDEDED;
  }

  .z-10 {
    z-index: 10;
  }

  .smallIcon {
    position: absolute !important;
    left: 8px;
    top: 3px;
    font-weight: 800;
  }

  .dial-btn {
    bottom: 20px !important;
    top: auto !important;
    @include media-breakpoint-up(md) {
      top: 16px !important;
      bottom: auto !important;
    }
    &.disabled {
      background: gray !important;
      border-color: grey !important;
      &::after {
        content: 'Please buy at least one phone number';
        position: absolute;
        left: -20px;
        top: 50%;
        translate: -100% -50%;
        background: gray;
        padding: 5px 10px;
        border-radius: 5px;
        font-size: 1em;
        text-transform: none;
        display: none;
      }
      &:hover::after {
        display: block;
      }
    }
  }

 .mobile-dial-btn {
    top: 0 !important;
    right: 15%;
    margin-top: 65px !important;
  }

 .active-calls {
   height: calc(100vh - 100px);
   overflow-y: auto;
   position: fixed;
 }
  @media screen and (max-width: 960px){
    .active-calls {
      height: auto;
      position: relative;
    }
  }

  @media screen and (max-width: 575px){
    .dashboard-page-class {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 0 !important;
    }
    .dial-btn.disabled::after {
      font-size: .8em;
      left: 0;
      top: unset;
      bottom: -60px;
      translate: -61% -100%;
    }
  }
</style>
